import { Injectable } from "@angular/core";
// import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  getUserList(): Observable<any> {
    const api = "https://jsonplaceholder.typicode.com/users";
    return this.httpClient.get(api);
  }
  getDoorList(): Observable<any> {
    const api = "https://nrmapi.azurewebsites.net/api/GetDoors";
    return this.httpClient.get(api);
  }

  // Get All Users Endpoint
  getUserAptList(): Observable<any> {
    const api =
      "https://nrmapi.azurewebsites.net/api/Userapt?aptid=D4C888BE-C32A-4F5D-A803-A8C368317E7B";
    return this.httpClient.get(api);
  }

  postUser(userData): Observable<any> {
    console.log("in post req - create: ", userData);
    const api = " https://nrmapi.azurewebsites.net/api/CreateUser";
    return this.httpClient.post(api, userData);
  }

  updateUser(userData): Observable<any> {
    console.log("in put req - update: ", userData);
    const api = "https://nrmapi.azurewebsites.net/api/UpdateUser";
    return this.httpClient.post(api, userData);
  }

  deleteUser(id): Observable<any> {
    console.log("in delete req - delete: ", id);
    // const api = `https://nrmapi.azurewebsites.net/api/DeleteUser?id=90459605-533B-4486-A22C-3373C4C006C6`;
    const api = `https://nrmapi.azurewebsites.net/api/DeleteUser?id=${id}`;
    return this.httpClient.post(api, {});
  }
}
